.container {
    background-color: #464A4F;
    font-family: Roboto;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(0deg, rgba(249, 252, 255, 0.03), rgba(249, 252, 255, 0.03)), #292A2B;
    box-shadow: 0px 1px 1px rgba(30, 30, 32, 0.14), 0px 2px 1px rgba(30, 30, 32, 0.12), 0px 1px 3px rgba(30, 30, 32, 0.2);
}

.selected {
    background: linear-gradient(0deg, rgba(249, 252, 255, 0.08), rgba(249, 252, 255, 0.08)), linear-gradient(0deg, rgba(249, 252, 255, 0.11), rgba(249, 252, 255, 0.11)), #292A2B;
}

.listDivider {
    background: #292a2b;
    opacity: 0.92;
    height: 3px;
    width: auto;
}

.content {
    padding: 2px 10px;
    text-align: left;
    font-size: 14px;
    color: #FFFFFF;
    height: 102px;
    max-height: 102px;
}

.managementAreaLabel {
    padding-left: 3px;
    max-width: 98px;
    padding-top: 7px;
    /* padding-bottom: 3px; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

.progressLabel {
    font-size: 13px;
    line-height: 18px;
    margin-top: 5px;
    float: right;
}

div.assetsDetails {
    margin-top: -5px;
    padding-left: 3px;
    color: #FFFFFF
}

.bottomText {
    padding-left: 3px;
    margin-bottom: 5px;
    color: rgba(255,255,255,0.7);
}

.bottomText span ~ span {
    padding-left: 10px;
}

.programTitle {
    margin-top: 5px;
}

.progressRingContainer {
    position: relative;
    height: 45px;
    width: 45px;
}

.progressRingOverlay,
.progressRingBackground {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
}

.progressRingBackground {
    border: 3.6px solid rgba(255,255,255,0.12);
    border-radius: 50%;
}