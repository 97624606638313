.itemHeader {
  color: #747474;
}

.itemContent {
  color: white;
}

.programTitle {
    margin-top: 5px;
}

.avatar {
    color: #161616;
    margin-left: 5px;
    width: 26px !important;
    height: 26px !important;
}

.avatar.cyclic {
    background-color: #aa9639 !important;
}

.avatar.other {
    background-color: #C2B3E5 !important;
}

