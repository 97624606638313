
.avatar {
    color: #161616;
    margin-left: 5px;
    width: 26px !important;
    height: 26px !important;
}
.avatar.cyclic {
    background-color:#aa9639 !important;
}
.avatar.other {
    background-color: #C2B3E5 !important;
}
.badgeColor {
    background-color: #E94F3A !important;
    color: #161616;
}
.tooltipTitle {
    text-decoration: underline;
    font-weight: bold;
    text-transform: capitalize;
}