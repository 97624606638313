.itemHeader {
    color: #747474;
    font-size: 11px !important;
}

.itemContent {
    color: white;
    font-size: 11px !important;
}

.checkCircle {
    height: 15px !important;
    width: 15px !important;
    margin-left: 5px;
    margin-top: 10px;
    background-color: #292A2B;
}

