.container {
    font-family: Roboto;
    font-size: 14px;
    width: 280px;
    max-height: 400px;
    background-color: #424345ec;
    color: #D6D7D8;
    border-radius: 4px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.content {
    padding:7px 0px 7px 9px;
    align-items: flex-start;
    display: flex;
    text-align: left;
    width: 100%
}

.header
{
   align-items: center;
   display: 'flex';
}

.progressRingContainer {
    position: relative;
    height: 40px;
    width: 40px;
}
.progressRingOverlay,
.progressRingBackground {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
}

.progressRingBackground {
    border: 3.6px solid rgba(255,255,255,0.12);
    border-radius: 50%;
}

.clear {
    font-size: 16px !important;
}