.container {
    background-color: #3C3D3E;
    font-family: Roboto;
    border-radius: 5px;
}

.content {
    padding: 2px 10px 2 px 10px;
    text-align: left;
    font-size: 14px;
    color: #D6D7D8;
}