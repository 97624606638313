.itemHeader {
  color: #747474;
}

.itemContent {
  color: white;
}

.draggableWindowHeader {
    background-color: #404142;
    width: 100%;
    height: 48px;
}

.draggableWindowHeaderContent {
    padding: 15px 15px 0px 0px;
    background-color: #404142;
    display: flex;
    justify-content: flex-end;
}

.linkPointer:hover {
    cursor: pointer;
}

.windowContainer {
    z-index: 1200;
    position: absolute;
    right: 460px;
    top: 135px;
    display: block;
    width: 500px;
    background-color: #222222;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.moveCursor {
    cursor: move;
}