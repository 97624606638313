.container {
    background-color: #3C3D3E;
    font-family: Roboto;
    border-radius: 5px;
    width: 230px;
}

.content {
    padding: 0px 10px 0px 10px;
    text-align: left;
    font-size: 14px;
    color: #D6D7D8;
}
