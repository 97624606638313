.avatarCyclic {
  margin-left: 5px;
  color: #161616;
  background-color: #aa9639 !important;
  width: 30px !important;
  height: 30px !important;
}
.avatarOther {
  margin-left: 5px;
  color: #161616;
  background-color: #c2b3e5 !important;
  width: 30px !important;
  height: 30px !important;
}
