.container {
    background-color: #464A4F;
    font-family: Roboto;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(0deg, rgba(249, 252, 255, 0.03), rgba(249, 252, 255, 0.03)), #292A2B;
    box-shadow: 0px 1px 1px rgba(30, 30, 32, 0.14), 0px 2px 1px rgba(30, 30, 32, 0.12), 0px 1px 3px rgba(30, 30, 32, 0.2);
}

.dot {
    border: 1px solid white;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-left: 5px;
    margin-right: 5px;
}